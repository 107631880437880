import React from "react";
import secret from "../assets/secret.png";
import secretsmall from "../assets/secretsmall.png";
import jerknow from "../assets/jerknow.png";

function Secret() {
  return (
    <div className="p-4 flex flex-col justify-center items-center" id="secret">
      <img src={secretsmall} alt="" className="w-fit lg:hidden" />
      <img src={secret} alt="" className="w-fit hidden lg:flex" />
      <a
        href="https://birdeye.so/token/BGhxD7svTFHjLgB8S62dF5TbBExuMx64JD4WKuQnQdNX?chain=solana"
        target="_blank"
        rel="noreferrer"
      >
        <img src={jerknow} alt="" className="w-fit p-5 lg:self-end" />
      </a>
    </div>
  );
}

export default Secret;
